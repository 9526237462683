import "./Footer.css"

const Footer = () => {
  return (
    <footer>
        <small>
            Justin Aquino, Fullstack Engineer 2022
        </small>
    </footer>
  )
}

export default Footer